.loading-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  min-height: 100vh;
  z-index: 1000;

  &--content {
    display: flex;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: #fff;
  }
}